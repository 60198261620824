<script lang="ts" setup>
const userStore = useUserStore();
const authStore = useAuthStore();
const onboardingStore = useOnboardingStore();
const discountStore = useDiscountStore();
const checkoutStore = useCheckoutStore();
const biTrackingStore = useBiTrackingStore();
const { user } = storeToRefs(userStore);

async function logout() {
  authStore.$reset();
  userStore.$reset();
  onboardingStore.$reset("logout");
  discountStore.$reset();
  checkoutStore.$reset();
  biTrackingStore.$reset();

  await navigateTo("/account/login");
}
</script>
<template>
  <button v-if="user" class="font-medium text-yz-blue-600" @click="logout">
    {{ $t("user.settings.button.logout") }}
  </button>
</template>
